<template>
  <v-container fluid :max-width="3128" class="pb-0">
    <slot name="prepend"></slot>

    <v-row>
      <v-col
          v-for="(item, index) in completeRows"
          :key="index"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
      >
        <SimmerCard :item="item" :showProfileLinks="props.showProfileLinks" :eager="eagerImageLoad"></SimmerCard>
      </v-col>
    </v-row>
    <slot name="append"></slot>
  </v-container>
</template>

<script setup>
import SimmerCard from "~/components/cards/SimmerCard.vue";

const props = defineProps({
  cardDataArray: Array,
  useCompleteRows: Boolean,
  showProfileLinks: Boolean,
  eagerImageLoad: Boolean
});

const currentBreakpoint = ref('xl');

const itemsPerRow = computed(() => {
  const breakpointMap = {
    xl: 6,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1
  };
  return breakpointMap[currentBreakpoint.value];
});

const completeRows = computed(() => {
  if (props.useCompleteRows === false) {
    return props.cardDataArray;
  }

  const rowSize = itemsPerRow.value;

  if (props.cardDataArray.length <= rowSize) {
    return props.cardDataArray;
  }

  const completeRowCount = Math.floor(props.cardDataArray.length / rowSize);
  return props.cardDataArray.slice(0, completeRowCount * rowSize);
});

onMounted(() => {
  const updateBreakpoint = () => {
    const width = window.innerWidth;
    if (width >= 1904) currentBreakpoint.value = 'xl';
    else if (width >= 1264) currentBreakpoint.value = 'lg';
    else if (width >= 960) currentBreakpoint.value = 'md';
    else if (width >= 600) currentBreakpoint.value = 'sm';
    else currentBreakpoint.value = 'xs';
  };

  window.addEventListener('resize', updateBreakpoint);
  updateBreakpoint();

  onUnmounted(() => {
    window.removeEventListener('resize', updateBreakpoint);
  });
});
</script>
